import androidx.compose.runtime.*
import crypstie3.DecryptedCrypstie
import kotlinx.browser.window
import kotlinx.coroutines.await
import net.sergeych.mp_tools.globalLaunch
import net.sergeych.unikrypto.Unicrypto
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.dom.*

@Composable
fun ShowCrypstie() {
    val pathpart = window.location.pathname.substring(1) + window.location.hash
    var crypstie by remember { mutableStateOf<DecryptedCrypstie?>(null) }
    var error by remember { mutableStateOf<String?>(null) }
    var loading by remember { mutableStateOf(true) }

    if (loading) {
        H3 {
            Div2("spinner-border me-2")
            Textl("Decrypting crypstie...")
        }
    } else if (error != null) {
        Alerts.WARNING.render {
            H3 { Textl("Failed to load crypstie:") }
            Div2("mt-2") {
                Textl(error ?: "")
            }
            A("/") {
                Textl("<< back")
            }
        }
    } else {
        if (crypstie?.burnOnShow == true) {
            if (crypstie?.ownerHandle != null)
                Alerts.WARNING.render {
                    Textl("burnOnAlert".i)
                }
            else
                Alerts.DANGER.render {
                    H4 { Textl("This is a burn-on-show crypstie!") }
                    Textl("burnedWarning")
                }
        }
        Label { Textl("Decrypted crypstie:") }
        Div2("mt-2 w-100 border position-relative") {
            Span({
                classes("position-absolute", "top-0", "end-0")
            }) {
                val copyId = "copyCrypstieButton"
                Icon.CLIPBOARD.render({
                    style { cursor("pointer") }
                    classes("border", "m-1")
                    id(copyId)
                    attr("data-bs-content", "contents are copied")
                    onClick {
                        try {
                            crypstie?.text?.let {
                                window.navigator.clipboard.writeText(it)
                                js(
                                    """
                                    var elem = document.getElementById(copyId);
                                    var popover = new bootstrap.Popover(elem, {position:'left', trigger:'manual'});
                                    popover.show();
                                    setTimeout(function() { popover.hide() }, 1500);
                                """
                                )
                            }
                        } catch (t: Throwable) {
                            t.printStackTrace()
                        }
                        it.preventDefault()
                    }
                })
            }
            Pre({
                classes("m-2")
                id("decryptedCrypstie")
            }) { Code { Textl(crypstie?.text ?: "") } }
            globalLaunch {
                try {
                    js("hljs.highlightAll();")
                } catch (t: Throwable) {
                    t.printStackTrace()
                }
            }
        }
        Button({
            classes("btn", "btn-success", "mt-2")
            onClick {
                Router.replace("/")
            }
        }) {
            Textl("new crypstie...")
        }
    }

    LaunchedEffect("loadCrypstie") {
        try {
            Unicrypto.unicryptoReady.await()
            crypstie = crypstieClient.decryptPath(pathpart, Settings.profileIds)
        } catch (t: Throwable) {
            t.printStackTrace()
            error = t.toString()
        } finally {
            loading = false
        }
    }
}