import kotlinx.browser.localStorage
import tools.randomId

object Settings {

    val profileIds by CachedValueDelegate<List<String>>() { name ->
        localStorage.getItem(name)?.let {
            try {
                val pp = it.split(",")
                if (pp.isEmpty())
                    null
                else
                    pp
            } catch (t: Throwable) {
                t.printStackTrace()
                null
            }
        } ?: run {
            listOf(randomId(13)).also {
                localStorage.setItem(name, it.joinToString(","))
            }
        }
    }

    var languageIndexOverride: Int? by CachedValueDelegate(null,
        { name, value ->
            if (value == null) localStorage.removeItem(name)
            else localStorage.setItem(name, "$value")
        },
        { name ->
            localStorage.getItem(name)?.let {
                try {
                    it.toInt()
                } catch (x: Exception) {
                    x.printStackTrace()
                    null
                }
            }
        }
    )

    val ownerHandle by lazy { profileIds.first() }

}

