import androidx.compose.runtime.*
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import kotlinx.datetime.Instant
import net.sergeych.mp_tools.globalLaunch
import net.sergeych.unikrypto.Unicrypto
import org.jetbrains.compose.web.attributes.ButtonType
import org.jetbrains.compose.web.attributes.onSubmit
import org.jetbrains.compose.web.attributes.readOnly
import org.jetbrains.compose.web.attributes.type
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLTextAreaElement

@Composable
fun PublishAndShow(
    text: String,
    deleteAt: Instant,
    burn: Boolean,
    onDone: (String?) -> Unit,
) {
    var inProgress by remember { mutableStateOf(true) }
    var newLink by remember { mutableStateOf("") }
    var newPath by remember { mutableStateOf("") }
    var doneCalled = remember { false }

    Div2("modal", {
        id("readyModal")
        attr("tabindex", "-1")
    }) {

        DisposableEffect("autocloseModal7") {
            val htmlDivElement: HTMLDivElement = scopeElement
            val bsModal: Modal = js("new bootstrap.Modal(htmlDivElement)")
            bsModal.show()
            htmlDivElement.addEventListener("hidden.bs.modal", { e ->
                console.log(e)
                if( !doneCalled ) {
                    doneCalled = true
                    onDone(null)
                }
            }, true)
            onDispose {
                bsModal.hide()
                bsModal.dispose()
            }
        }

        LaunchedEffect("createCryp") {
            Unicrypto.unicryptoReady.await()
            newPath = "/" + crypstieClient.createPath(text, deleteAt, burn, ownerHandle = Settings.ownerHandle)
            newLink = "${window.location.protocol}//${window.location.host}$newPath"
            inProgress = false
        }

        val scope = rememberCoroutineScope()

        Div2("modal-dialog") {
            Div2("modal-content") {
                Div2("modal-header") {
                    H5({ classes("modal-title") }) {
                        Textl(
                            if (inProgress)
                                "Creating crypstie..."
                            else
                                "Crypstie ready!"
                        )
                    }
                    Button({
                        type(ButtonType.Button)
                        classes("btn-close")
                        attr("data-bs-dismiss", "modal")
                    })
                }
                Div2("modal-body") {
                    if (inProgress) {
                        Div2("text-center") {
                            Div2("spinner-border")
                        }
                    } else {
                        val fieldId = "newCrypField"
                        Form {
                            Div2("mt-2") {
                                Label { Textl("Crypstie link:") }
                                TextInput(newLink) {
                                    id(fieldId)
                                    readOnly()
                                    classes("form-control")
                                }
                                LaunchedEffect("focusOnNewCryp") {
                                    (document.getElementById(fieldId) as? HTMLInputElement)?.focus()
                                }
                            }
                            Div2("text-center mt-2") {
                                Button({
                                    classes("btn", "btn-success")
                                    type(ButtonType.Submit)
                                    onClick {
                                        it.preventDefault()
                                        scope.launch {
                                            try {
                                                window.navigator.clipboard.writeText(newLink).await()
                                                onDone(newPath)
                                                doneCalled = true
                                            } catch (t: Throwable) {
                                                t.printStackTrace()
                                            }
                                        }
                                    }
                                }) {
                                    Textl("Copy and open (enter)")
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}