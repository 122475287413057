import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.I
import org.w3c.dom.HTMLElement

enum class Icon(val styleClass: String) {
    SEARCH("search"),
    UNDER_CONSTRUCTION("cone-striped"),
    INFO("info-circle"),
    BUILDING("building"),
    PROFILE("person-rolodex"),
    COMPANY("briefcase"),
    PEOPLE("people"),
    CLIPBOARD("clipboard"),
    DANGER("radioactive");

    @Composable
    fun render(attrs: AttrBuilderContext<HTMLElement>?=null) {
        I({
            classes("bi", "bi-$styleClass", "me-1")
            attrs?.invoke(this)
        })
    }
}