import kotlin.reflect.KProperty

@Suppress("UNCHECKED_CAST")
class CachedValueDelegate<T>(private val key: String? = null,
                             private val setter: ((String,T?)->Unit)? = null,
                             private val getter: (String)->T) {

    private var ready = false
    private var cached: T? = null

    operator fun getValue(target: Any,prop: KProperty<*>): T {
        if( ready ) return cached as T
        cached = getter(key ?: prop.name)
        ready = true
        return cached as T
    }
    operator fun setValue(target: Any,prop: KProperty<*>,value: T?): T {
        cached = value
        ready = true
        setter?.invoke(key ?: prop.name, value)
        return cached as T
    }
}