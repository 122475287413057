import androidx.compose.runtime.*
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.flow.MutableStateFlow
import net.sergeych.mp_logger.LogTag
import net.sergeych.mp_logger.info
import net.sergeych.mp_logger.warning

object Router : LogTag("ROUTR") {

    var regex: Regex? = null
        private set

    var match: MatchResult? = null
        private set

    val defaultTitle = "Crypstie"

    data class Target(val title: String = defaultTitle, val content: @Composable () -> Unit)

    fun param(index: Int): String? =
        match?.groupValues?.get(index + 1)

    val userRouting = listOf<Pair<String, Target>>().map { (k, v) -> Regex(k) to v }

    val guestRouting = listOf(
        "/" to Target { Home() },
        "/_.+" to Target { ShowCrypstie() }
    ).map { (k, v) -> Regex(k) to v }

    private val reHashtag = Regex("#[^?]+")
    private var pathFlow = MutableStateFlow(targetFor(document.location?.pathname ?: "/"))

    init {
        window.onpopstate = {
            document.location?.pathname?.let { loc ->
                pathFlow.value = targetFor(loc).also {
                    document.title = it.title
                }
            } ?: warning { "popstate without location: ${it.state}" }
        }
        document.title = pathFlow.value.title
    }

    private fun targetFor(urlString: String): Target {
        val url = urlString.replace(reHashtag, "")
        val routes = guestRouting
        for ((re, target) in routes) {
            re.matchEntire(url)?.let {
                match = it
                return target
            }
        }
        warning { "route not found: $url" }
        return routes[0].second
    }

    fun push(url: String) {
        val t = targetFor(url)
        window.history.pushState(url, t.title, url)
        document.title = t.title
        pathFlow.value = t
    }

    fun back() {
        window.history.go(-1)
    }

    fun replace(url: String) {
        targetFor(url).let {
            window.history.replaceState(null, it.title, url)
            pathFlow.value = it
            document.title = it.title
        }
    }

    @Composable
    fun contents() {
        var target by remember { mutableStateOf(pathFlow.value) }

        target.content()

        LaunchedEffect("collectFlow") {
            pathFlow.collect { target = it }
        }

    }
}
