import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement

enum class Alerts(val cssClass: String) {
    PRIMARY("primary"),
    DANGER("danger"),
    WARNING("warning");

    @Composable
    fun render(
        attrBuilderContext: AttrBuilderContext<HTMLDivElement>? = null,
        content: ContentBuilder<HTMLDivElement>,
    ) {
        Div({
            classes("alert", "alert-${cssClass}")
            attrBuilderContext?.invoke(this)
        }, content)
    }
}