import kotlinx.browser.window

object I18n {

    // Internationalization
    //  123456789012345678

    val languages = listOf("ru")

    val translations = mapOf<String, List<String>>(
        "Enter crypstie:" to listOf("Введите текст для крипсти:"),
        "days" to listOf("дней"),
        "Burn on show" to listOf("сжечь по прочтении"),
        "Create" to listOf("cоздать"),
        "about crypstie" to listOf("про крипсти"),
        "Creating crypstie..." to listOf("Создаю крипсти..."),
        "Crypstie ready!" to listOf("Крипсти готова!"),
        "Crypstie link:" to listOf("Ссылка на крипсти:"),
        "Copy and open (enter)" to listOf("Скопирвать и открыть (enter)"),
        "Decrypting crypstie..." to listOf("Расшифровываю крипсти..."),
        "Failed to load crypstie:" to listOf("Ошибка доступа:"),
        "<< back" to listOf("<< назад"),
        "This is a burn-on-show crypstie!" to listOf("Это крипсти типа сжечь-по-прочтении!"),
        "Decrypted crypstie:" to listOf("Расшифрованная крипсти:"),
        "contents are copied" to listOf("Данные скопированы"),
        "new crypstie..." to listOf("новая крипсти..."),
        "/about.html" to listOf("/about_ru.html"),
        "burnOnAlert" to listOf(
            """
            This is a burn-on-show crypstie. Since it was created from this browser instance, it
            will not be deleted right now, bit only when first shown in other instance.
            """.trimIndent(),
            """
            Эта крипсти имеет метку "после прочтения сжечь". Так как она была создана в этом браузере, она
            не будет удалена (Вы можете ее проверить), но как только ее откроют в другом браузере или в анонимном
            окне, она будет удалена.
            """.trimIndent(),
        ),
        "burnedWarning" to listOf(
            """
            Copy ot if you need, as it is already deleted off the server, and do not reload this page
            for it exists no more.
            """.trimIndent(),
            """
            Скопируйте ее если она вам нужна! Эта крипсти уже удалена с сервера, так что не перегружайте эту страницу,
            больше вы ее не увидите.
            """.trimIndent(),
        )
    )

    var langIndex: Int
        private set

    val flagResource: String
        get() = when (langIndex) {
            0 -> "ru.svg"
            else -> "gb.svg"
        }

    fun cycleLocale() {
        if (langIndex < 0)
            langIndex = 0
        else {
            if (++langIndex >= languages.size)
                langIndex = -1
        }
        Settings.languageIndexOverride = langIndex
    }

    init {
        @Suppress("UNNECESSARY_SAFE_CALL")
        langIndex = Settings.languageIndexOverride ?: when (window.navigator.language?.lowercase()?.take(2)) {
            "ru" -> 0
            null -> -1
            else -> -1
        }
    }

    fun translate(src: String): String =
        translations[src]?.let { list ->
            if (list.size <= languages.size) {
                // no primary value
                if (langIndex >= 0) list[langIndex]
                else null
            } else {
                // primary value overriden:
                list[langIndex + 1]
            }
        } ?: src
}

val String.i: String get() = I18n.translate(this)