import androidx.compose.runtime.*
import crypstie3.CrypstieClient
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlinx.datetime.Clock
import net.sergeych.mp_tools.globalLaunch
import net.sergeych.unikrypto.Unicrypto
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.attributes.rows
import org.jetbrains.compose.web.attributes.selected
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.*
import org.jetbrains.compose.web.renderComposable
import org.w3c.dom.HTMLTextAreaElement
import kotlin.time.Duration.Companion.days

val crypstieClient = CrypstieClient(
    when (window.location.hostname) {
        "localhost" -> "http://localhost:8296"
        "0.0.0.0" -> "http://localhost:8296"
        else -> "https://crypstie.com"
    }
)

@Composable
fun Textl(src: String) {
    Text(src.i)
}


fun main() {
    // start downloading big wasm in background:
    globalLaunch { Unicrypto.unicryptoReady.await() }
    renderComposable(rootElementId = "root") {
        Div({
            classes("container-md", "mt-2", "mb-2")
        }) {
            Router.contents()
        }
    }
}

@Composable
fun Home() {
    var createMode by remember { mutableStateOf(false) }
    var days by remember { mutableStateOf(90) }
    var burn by remember { mutableStateOf(false) }


    var text by remember { mutableStateOf("") }

    if (createMode)
        PublishAndShow(
            text,
            Clock.System.now() + days.days,
            burn
        ) { newLink ->
            createMode = false
            if (newLink != null) {
                text = ""
                Router.replace(newLink)
            }
        }
    else {
        var flag by remember { mutableStateOf(I18n.flagResource) }

        Img(flag) {
            classes("float-end", "m-1", "border")
            style {
                width(2.em)
                height(auto)
            }
            onClick {
                I18n.cycleLocale()
                flag = I18n.flagResource
                window.location.reload()
            }
        }

        Label("ent") {
            Textl("Enter crypstie:")
        }

        TextArea(text) {
            id("ent")
            rows(10)
            classes("form-control")
            onInput { text = it.value }
            onKeyDown {
                if ((it.metaKey || it.ctrlKey) && it.code == "Enter")
                    createMode = true
            }
        }

        LaunchedEffect("focus1") {
            (document.getElementById("ent") as HTMLTextAreaElement).focus()
        }

        Div({ classes("row", "gx-4", "gy-2", "mt-2") }) {

            Div({ classes("col-auto") }) {
                Div({ classes("col-auto") }) {
                    Select({
                        classes("form-select")
                        onInput { e ->
                            days = e.value?.toInt() ?: 90
                            console.log("days set to ", days)
                        }
                    }) {
                        for (n in arrayOf(10, 30, 90, 180, 360, 440)) {
                            Option("$n", { if (n == days) selected() }) {
                                Text("$n ${"days".i}")
                            }
                        }
                    }
                }
            }
            Div({ classes("col") }) {
                Div({ classes("form-check") }) {
                    Input(InputType.Checkbox) {
                        classes("form-check-input")
                        checked(burn)
                        id("burn")
                        onInput { burn = !burn }
                    }
                    Label("burn", { classes("form-check-label") }) {
                        Textl("Burn on show")
                    }
                }
            }
            Div({ classes("col") }) {
                Button({
                    classes("btn", "btn-primary", "float-end")
                    if (text.isBlank()) disabled()
                    onClick {
                        createMode = true
                    }
                }) {
                    Textl("${"Create".i} (ctrl+enter)")
                }
            }
        }
    }
    Hr()
    Div2("float-end") { Textl("012")}
    Div2("text-center small") {
        A("/about.html".i) { Textl("about crypstie")}
    }
}

